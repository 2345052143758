const currentMetrics = {
  totalPageViews: 59167,
  uniquePageViews: 47655,
  totalUsers: 26399,
  avgSession: '1:54',
  avgSessionMedia: '13:08',
  facebookPlaybackMinutes: '+2.1 Mill',
  userProfile: {
    male: 48.2,
    female: 51.8
  },
  userAges: {
    values: [
      {
        label: '18-24',
        percentage: 14.5
      },
      {
        label: '25-34',
        percentage: 44.1
      },
      {
        label: '35-44',
        percentage: 29.1
      },
      {
        label: '45-54',
        percentage: 8.7
      },
      {
        label: '55-64',
        percentage: 2.5
      },
      {
        label: '65+',
        percentage: 1.1
      }
    ],
    maxValue: 45
  },
  accessChanels: {
    desktop: 17.4,
    tablet: 0.8,
    mobile: 81.8
  },
  socialMedia: {
    fbFollowers: 72049,
    igFollowers: 7947
  }
}

export default currentMetrics
