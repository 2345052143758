import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'
import VideoJS from '../../../shared/VideoJS'

export function Welcome (props) {
  const videoJsOptions = { // lookup the options in the docs for more options
    techOrder: ['youtube'],
    sources: [{
      src: 'https://youtu.be/kUTU8mp5PtY',
      type: 'video/youtube'
    }],
    youtube: { ytControls: 0, rel: 0, modestbranding: 1, showinfo: 0 }
  }
  return (
    <ContentSection id='welcome' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='v-aling col-12 col-lg-7'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              ¿Por qué Yok?
            </h1>
            <p data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'>
              Porque podrá encontrar todos los servicios que necesite con nosotros:
            </p>
            <p data-aos='fade-up' data-aos-delay='400' data-aos-duration='1000'>
              Producción Audio Visual, Media Digital, Implementación, Community Manager, Diseño Web, Podcast, Radio, Locución Profesional, Estudio de Grabación, Materiales Promocionales, Impresión Pequeño y Gran Formato.
            </p>
            <p data-aos='fade-up' data-aos-delay='600' data-aos-duration='1000'>
              <strong style={{ fontSize: '1.4rem' }}>Yok</strong> fundada en octubre del 2009 y con más de 10 años en el mercado, desarrollando experiencias en múltiples sectores de la economía y distintas industrias del país tales como: consumo masivo, telecomunicaciones, tecnología, restaurantes – alimentación, servicios, moda, turismo, deporte y entretenimiento.
            </p>
          </div>
          <div className='v-aling col-12 col-lg-5'>
            <VideoJS options={videoJsOptions} />
          </div>

        </div>
      </div>
    </ContentSection>
  )
}
