import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'
import YokIcon from 'fonts/YokIcons/index.js'
import VideoJS from '../../../shared/VideoJS'

function StationIcon (props) {
  const color = '#fff'
  return (
    <p className='station-icon'>
      <span>
        <YokIcon
          icon={props.icon}
          color={color}
        />
      </span>
    </p>
  )
}

const stations = [
  [
    {
      name: 'Trendy',
      description: 'Escucha las canciones más sonadas del momento o bien la música más Trendy, géneros como: reggaetón, pop, R&B y más.',
      icon: 'ra-urban',
      id: 'trendy'
    },
    {
      name: 'Legend',
      description: 'Revive las canciones que nunca pasarán de moda y que ahora son un clásico, música para recordar en sus diferentes géneros de Rock, Soul, Disco, Pop y muchos más.',
      icon: 'ra-guitar',
      id: 'legend'
    }
  ],
  [
    {
      name: 'Latin Sound',
      description: 'Música autóctona de Latinoamérica, los ritmos que corren por tus venas, entre estos se encuentran: Salsa, Merengue, Cumbia, Bachata, entre otros.',
      icon: 'ra-tropical',
      id: 'latinsound'
    },
    {
      name: 'Beat',
      description: 'Canciones que te llegan al alma, que tocan las fibras de tu corazón, los artistas más representativos de grandes géneros como: Baladas en Español y en Inglés, Pop,  Románticas, Música Plancha, etc.',
      icon: 'ra-love',
      id: 'beat'
    }
  ],
  [
    {
      name: 'Quinta Estación',
      description: 'Los mejores éxitos del Rock y el Pop en español, un viaje al pasado para recordar grandes bandas y máximos exponentes latinoamericanos de la música, con pequeñas intervenciones de programas en vivo.',
      icon: 'yr-microphone',
      id: 'live'
    }
  ]
]

export function Radio (props) {
  const stationContainerClassName = 'col-12 col-sm-6 col-md-5 col-lg-4'
  const videoJsOptions = { // lookup the options in the docs for more options
    techOrder: ['youtube'],
    sources: [{
      src: 'https://youtu.be/X8YgrSdCwK0',
      type: 'video/youtube'
    }],
    youtube: { ytControls: 0, rel: 0, modestbranding: 1, showinfo: 0 }
  }

  return (
    <ContentSection id='radio' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='fancy-background col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>Yok Radio</h1>
            <div className='text' data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'>
              <p>
                Contamos con 4 estaciones en una sola radio digital.
              </p>
              <p>
                Diseñada para satisfacer todos los gustos musicales de sus oyentes.
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          {stations[0].map((station, id) => (
            <div
              key={id}
              className={stationContainerClassName}
              data-aos='fade-up' data-aos-delay={(id * 100) + 200} data-aos-duration='1000'
            >
              <h2 className='section-subtitle'>
                <a href={`https://yokradio.com/${station.id}`} target='_blank' rel='noopener noreferrer'>
                  <StationIcon icon={station.icon} />
                </a>
                {station.title}
              </h2>
              <p className='fancy-background'>
                {station.description}
              </p>
            </div>
          ))}
        </div>
        <div className='row justify-content-center'>
          {stations[1].map((station, id) => (
            <div
              key={id}
              className={stationContainerClassName}
              data-aos='fade-up' data-aos-delay={(id * 100) + 200} data-aos-duration='1000'
            >
              <h2 className='section-subtitle'>
                <a href={`https://yokradio.com/${station.id}`} target='_blank' rel='noopener noreferrer'>
                  <StationIcon icon={station.icon} />
                </a>
                {station.title}
              </h2>
              <p className='fancy-background'>
                {station.description}
              </p>
            </div>
          ))}
        </div>
        <div className='row justify-content-center'>
          {stations[2].map((station, id) => (
            <div
              key={id}
              className={stationContainerClassName}
              data-aos='fade-up' data-aos-delay={(id * 100) + 200} data-aos-duration='1000'
            >
              <h2 className='section-subtitle'>
                <a href={`https://yokradio.com/${station.id}`} target='_blank' rel='noopener noreferrer'>
                  <StationIcon icon={station.icon} />
                </a>
                {station.title}
              </h2>
              <p className='fancy-background'>
                {station.description}
              </p>
            </div>
          ))}
        </div>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8'>
            <VideoJS options={videoJsOptions} />
            {/* <div
              data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'
              className='ratio ratio-16x9'
            >
              <iframe title='Yok Radio' src='https://www.youtube.com/embed/X8YgrSdCwK0' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
            </div> */}
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
