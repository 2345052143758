import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'fonts/YokIcons/selection.json'

function YokIcon (props) {
  return (
    <IcomoonReact
      iconSet={iconSet}
      className={props.className}
      color={props.color}
      size={props.size}
      icon={props.icon}
    />
  )
}

export default YokIcon
