import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

SwiperCore.use([Autoplay])

const baseUrl = `${process.env.PUBLIC_URL}/assets/images/customers/white/`
const logos = [
  `${baseUrl}arcadia.jpg`,
  `${baseUrl}asevertec.jpg`,
  `${baseUrl}claro.jpg`,
  `${baseUrl}conzultek.jpg`,
  `${baseUrl}facela.jpg`,
  `${baseUrl}labfc.jpg`,
  `${baseUrl}parrillitadepepe.jpg`,
  `${baseUrl}prestamas.jpg`
]

export function Portfolio (props) {
  return (
    <ContentSection id='portfolio' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              Algunos de nuestros clientes:
            </h1>
          </div>
        </div>
        <div
          className='row customers-logos'
          data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'
        >
          <div className='col'>
            <Swiper
              loop
              autoplay={{
                delay: 2000,
                disableOnInteraction: false
              }}
              width='100%'
              slidesPerView='auto'
            >
              {logos.map((logo, id) => (
                <SwiperSlide width='150px' key={id}>
                  <figure>
                    <img src={logo} alt='' />
                  </figure>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
