import React from 'react'
import AOS from 'aos'
import './styles.scss'
import { Welcome } from './Welcome'
import { Strategies } from './Strategies'
import { Planning } from './Planning'
import { SocialMedia } from './SocialMedia'
import { Radio } from './Radio'
import { Seo } from './Seo'
import { Services } from './Services'
import { Metrics } from './Metrics'
import { MetricsRadio } from './MetricsRadio'
import { Portfolio } from './Portfolio'
import Footer from 'app/shared/Footer'

function App () {
  AOS.init({
    mirror: true
  })
  return (
    <div className='Home'>
      <Welcome smoothTo='planning' smoothDirection='down' />
      <Planning smoothTo='strategies' smoothDirection='down' />
      <Strategies smoothTo='social-media' smoothDirection='down' />
      <SocialMedia smoothTo='radio' smoothDirection='down' />
      <Radio smoothTo='seo' smoothDirection='down' />
      <Seo smoothTo='services' smoothDirection='down' />
      <Services smoothTo='metrics' smoothDirection='down' />
      <Metrics smoothTo='portfolio' smoothDirection='down' />
      <MetricsRadio smoothTo='portfolio' smoothDirection='down' />
      <Portfolio smoothTo='welcome' smoothDirection='up' />
      <Footer />
    </div>
  )
}

export default App
