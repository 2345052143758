import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'

export function SocialMedia (props) {
  return (
    <ContentSection id='social-media' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='fancy-background col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              Redes Sociales
            </h1>
            <p data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'>
              En <strong style={{ fontSize: '1.4rem' }}>Yok</strong> podemos guiarlo para que dé un paso más allá del servicio al cliente y saque el mejor provecho al manejo de comunidades digitales: obtenga percepciones y sepa qué contenido es el que genera mejores reacciones en el momento correcto.
            </p>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
