import React from 'react'
import currentMetrics from './currentMetrics'
import CountUp from 'react-countup'
import { ContentSection } from 'app/shared/ContentSection'
import YokIcon from 'fonts/YokIcons/index.js'

function AgeRow (props) {
  const max = currentMetrics.userAges.maxValue > 90 ? currentMetrics.userAges.maxValue : currentMetrics.userAges.maxValue + 10
  const percentage = (props.percentage * 100) / max
  return (
    <div key={props.key} className='age-row'>
      <span className='label'>{props.label}:</span>
      <div className='progress'>
        <div
          className='progress-bar'
          role='progressbar'
          style={{ width: `${percentage}%` }}
          aria-valuenow={props.percentage}
          aria-valuemin='0'
          aria-valuemax={currentMetrics.userAges.maxValue}
        />
        <span className='value'>{props.percentage}%</span>
      </div>
    </div>
  )
}

function BoxMetrics (props) {
  return (
    <div
      data-aos='fade-up' data-aos-delay={props.delay} data-aos-duration='1000'
      className='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2'
    >
      <figure className='box-metrics'>
        <figcaption className='v-aling'>
          <div>
            <p className='title'>
              {props.title}
            </p>
            <p className='number'>
              {typeof props.value === 'number' ? (<CountUp end={props.value} separator='.' duration={3} />) : (props.value)}
            </p>
          </div>
        </figcaption>
        {props.children}
      </figure>
    </div>
  )
}

function GenderBox (props) {
  return (
    <figure className='gender-box'>
      {props.children}
      <figcaption>
        <p className='value'>
          <CountUp end={props.value} decimal='.' duration={3} />%
        </p>
      </figcaption>
    </figure>
  )
}

function TechBox (props) {
  return (
    <figure>
      {props.children}
      <figcaption>
        <p className='value'>
          <CountUp end={props.value} decimal='.' duration={3} />%
        </p>
        <p className='label'>
          {props.label}
        </p>
      </figcaption>
    </figure>
  )
}

function SocialBox (props) {
  return (
    <figure>
      {props.children}
      <figcaption>
        <p className='value'>
          <CountUp end={props.value} decimal='.' duration={3} />
        </p>
        <p className='label'>
          {props.label}
        </p>
      </figcaption>
    </figure>
  )
}
export function MetricsRadio (props) {
  const image = 'https://yokmedios.com/assets/android-chrome-96x96.png'
  return (
    <ContentSection id='metrics-radio' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row'>
          <h1
            className='section-title'
            data-aos='fade-up' data-aos-duration='1000'
          >
            Nuestros Números - Yok Radio
          </h1>
        </div>
        <div className='row justify-content-md-center'>
          <BoxMetrics
            title='Total de Páginas Vistas'
            value={currentMetrics.totalPageViews}
            delay={100}
          >
            <YokIcon
              icon='chart-line-solid'
              color='#fff'
            />
          </BoxMetrics>
          <BoxMetrics
            title='Total páginas vistas únicas'
            value={currentMetrics.uniquePageViews}
            delay={200}
          >
            <YokIcon
              icon='user-solid'
              color='#fff'
            />
          </BoxMetrics>
          <BoxMetrics
            image={image}
            title='Total de Usuarios'
            value={currentMetrics.totalUsers}
            delay={300}
          >
            <YokIcon
              icon='users-solid'
              color='#fff'
            />
          </BoxMetrics>
          <BoxMetrics
            image={image}
            title='Tiempo promedio de visita de News'
            value={currentMetrics.avgSession}
            delay={400}
          >
            <YokIcon
              icon='user-clock-solid'
              color='#fff'
            />
          </BoxMetrics>
          {/* <BoxMetrics
            image={image}
            title='Tiempo promedio de visita de Media'
            value={currentMetrics.avgSessionMedia}
            delay={400}
          >
            <YokIcon
              icon='user-clock-solid'
              color='#fff'
            />
          </BoxMetrics> */}
          {/* <BoxMetrics
            image={image}
            title='Minutos de video reproducidos en Facebook'
            value={currentMetrics.facebookPlaybackMinutes}
            delay={400}
          >
            <YokIcon
              icon='user-clock-solid'
              color='#fff'
            />
          </BoxMetrics> */}
        </div>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3'>
            <section
              className='user-profile'
              data-aos='fade-up' data-aos-delay='500' data-aos-duration='1000'
            >
              <p className='title'>
                Perfil del Visitante
              </p>
              <GenderBox value={currentMetrics.userProfile.female}>
                <YokIcon
                  icon='mars-solid'
                  color='#fff'
                />
              </GenderBox>
              <GenderBox value={currentMetrics.userProfile.male}>
                <YokIcon
                  icon='venus-solid'
                  color='#fff'
                />
              </GenderBox>
            </section>
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3'>
            <section
              className='user-ages'
              data-aos='fade-up' data-aos-delay='600' data-aos-duration='1000'
            >
              <p className='title'>
                Rango de Edades
              </p>
              <div className='values'>
                {currentMetrics.userAges.values.map((interval) => (
                  <div key={interval.label}>
                    <AgeRow label={interval.label} percentage={interval.percentage} />
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4'>
            <section
              className='technologies'
              data-aos='fade-up' data-aos-delay='700' data-aos-duration='1000'
            >
              <p className='title'>Accesos por Dispositivo</p>
              <TechBox value={currentMetrics.accessChanels.mobile} label='Móviles'>
                <YokIcon
                  icon='mobile-alt-solid'
                  color='#fff'
                />
              </TechBox>
              <TechBox value={currentMetrics.accessChanels.desktop} label='Ordenador'>
                <YokIcon
                  icon='laptop-solid'
                  color='#fff'
                />
              </TechBox>
              <TechBox value={currentMetrics.accessChanels.tablet} label='Tablets'>
                <YokIcon
                  icon='tablet-alt-solid'
                  color='#fff'
                />
              </TechBox>
            </section>
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4'>
            <section
              className='followers'
              data-aos='fade-up' data-aos-delay='800' data-aos-duration='1000'
            >
              <p className='title'>Seguidores en redes</p>
              <SocialBox value={currentMetrics.socialMedia.fbFollowers} label='Facebook'>
                <YokIcon
                  icon='facebook-brands'
                  color='#fff'
                />
              </SocialBox>
              <SocialBox value={currentMetrics.socialMedia.igFollowers} label='Instagram'>
                <YokIcon
                  icon='instagram-brands'
                  color='#fff'
                />
              </SocialBox>
            </section>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
