import React from 'react'
import Home from './app/pages/Home'

function App () {
  return (
    <Home />
  )
}

export default App
