import React from 'react'

export default function Footer (props) {
  const colClass = 'col-12 col-sm-4 col-md-3 col-lg-3 col-xl-2'
  return (
    <footer>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className={colClass}>
            <a
              href='https://yokmedios.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Yok Medios
            </a>
          </div>
          <div className={colClass}>
            <a
              href='tel:+506-4440-0180'
              target='_blank'
              rel='noopener noreferrer'
            >
              4404-0180
            </a>
          </div>
          <div className={colClass}>
            <a
              href='https://yokradio.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Yok Radio
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
