import React from 'react'
import ReactDOM from 'react-dom'
import './fonts/Poppins/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.scss'
import 'aos/dist/aos.css'
import 'animate.css/animate.min.css'
import App from './App'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

var refreshing
navigator.serviceWorker.addEventListener('controllerchange',
  function () {
    if (refreshing) return
    refreshing = true
    window.location.reload()
  }
)
