import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'

export function Seo (props) {
  return (
    <ContentSection id='seo' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='fancy-background col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              SEO
            </h1>
            <p data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'>
              <strong style={{ fontSize: '1.4rem' }}>Yok</strong> mantiene un departamento completo de comunicación integral orientada a la mejora continua para vivir mejor. Ofrecemos los servicios integrales en investigación, publicidad, relaciones públicas, comunicación digital, experiencia, producción audiovisual, compra de medios y mercadeo directo.
            </p>
            <p data-aos='fade-up' data-aos-delay='400' data-aos-duration='1000'>
              Nos caracterizamos por el enfoque estratégico, excelencia en el servicio al cliente, capacidad de atender proyectos complejos, efectividad, eficiencia, flexibilidad, versatilidad, análisis para una respuesta rápida a tiempo.
            </p>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
