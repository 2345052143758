import React, { useRef, useEffect } from 'react'
import videojs from 'video.js'
import 'videojs-youtube'
import 'video.js/dist/video-js.css'

const VideoJS = (props) => {
  const videoRef = useRef(null)
  const { options } = props
  const defaultOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true
  }

  // This seperate functional component fixes the removal of the videoelement
  // from the DOM when calling the dispose() method on a player
  const VideoHtml = (props) => (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className='video-js vjs-big-play-centered'
        controls
      />
    </div>
  )

  useEffect(() => {
    const videoElement = videoRef.current
    const playerOptions = Object.assign(defaultOptions, options)
    console.info('playeOptions', playerOptions)
    let player
    if (videoElement) {
      player = videojs(videoElement, playerOptions)
    }
    return () => {
      if (player) {
        player.dispose()
      }
    }
  }, [options])

  return (<VideoHtml />)
}
export default VideoJS
