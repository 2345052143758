import React from 'react'
import YokIcon from 'fonts/YokIcons/index.js'

export function ContentSection (props) {
  const className = `page-section ${props.id}`
  const scrollTo = (id) => {
    try {
      document.querySelector(`#${id}`).scrollIntoView()
    } catch (e) {
      console.log('Can\'t Smooth', e)
    }
  }
  const btnIcon = props.smoothDirection === 'down' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
  const btnColor = props.buttonColor || '#fff'
  const btnClass = `btn ${props.animateButton === true ? 'animate__animated animate__bounce animate__slower animate__infinite' : ''}`
  return (
    <section id={props.id} className={className}>
      {props.children}
      <div className='next-container'>
        <button className={btnClass} onClick={() => { scrollTo(props.smoothTo) }}>
          <YokIcon
            icon={btnIcon}
            color={btnColor}
          />
        </button>
      </div>
    </section>
  )
}
