import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'

export function Services (props) {
  /*
  const services = [
    'Planeación estratégica de comunicación.',
    'Posicionamiento, creación de marcas, lanzamientos y relanzamientos.',
    'Creatividad, diseño gráfico y arte final.',
    'Planificación y compra de medios masivos y digitales.',
    'Estudio de grabación profesional.',
    'Manejo de redes sociales: Estrategia, creatividad, administración de redes sociales, community manager y pauta digital.',
    'Relaciones públicas: Investigación, Comunicación de la marca, manejo de crisis, comunicación interna y Responsabilidad social.',
    'Podcast promocionales y espacios para sus marcas.',
    'Producción audiovisual.',
    'Mercadeo directo.',
    'Activaciones promocionales.',
    'Servicio de investigación de medios.',
    'Servicio de investigación y monitoreo de medios de comunicación y publicitario'
  ]
  */
  const baseUrl = `${process.env.PUBLIC_URL}/assets/images/services/`
  const services = [
    {
      title: 'Planificación Estratégica',
      image: `${baseUrl}1-planeacion-estrategica.png`
    },
    {
      title: 'Creación, Diseño Gráfico',
      image: `${baseUrl}2-creacion.png`
    },
    {
      title: 'Posicionamiento de Marca',
      image: `${baseUrl}3-posicionamiento.png`
    },
    {
      title: 'Estudio de Grabación',
      image: `${baseUrl}4-estudio-de-grabacion.png`
    },
    {
      title: 'Relaciones Públicas',
      image: `${baseUrl}5-estudio-de-grabacion.png`
    },
    {
      title: 'Mercadeo Directo',
      image: `${baseUrl}6-mercadeo-directo.png`
    },
    {
      title: 'Producción Audiovisual',
      image: `${baseUrl}7-produccion-audiovisual.png`
    },
    {
      title: 'Activación Promocional',
      image: `${baseUrl}8-activacion-promocional.png`
    },
    {
      title: 'Planificación de Compra',
      image: `${baseUrl}9-planifiacion-de-compra.png`
    },
    {
      title: 'Manejo de RRSS',
      image: `${baseUrl}10-manejo-de-redes.png`
    },
    {
      title: 'Podcast',
      image: `${baseUrl}11-podcast.png`
    },
    {
      title: 'Servicio de Investigación',
      image: `${baseUrl}12-servicio-de-investigacion.png`
    }
  ]

  return (
    <ContentSection id='services' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection} animateButton>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='fancy-background col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              Servicios
            </h1>
          </div>
        </div>
        <div className='row justify-content-center'>
          {(services.map((service, id) => (
            <div
              className='v-aling col-12 col-sm-5 col-md-4 col-lg-3'
              data-aos='fade-up' data-aos-delay={200 + (id * 100)} data-aos-duration='1000'
              key={id}
            >
              <figure className='service-box'>
                <img src={service.image} alt={service.title} />
                <figure>
                  <p>
                    {service.title}
                  </p>
                </figure>
              </figure>
            </div>
          )))}
        </div>
      </div>
    </ContentSection>
  )
}
