import React from 'react'
import { ContentSection } from 'app/shared/ContentSection'

export function Planning (props) {
  return (
    <ContentSection id='planning' smoothTo={props.smoothTo} smoothDirection={props.smoothDirection}>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='fancy-background col-12 col-lg-8'>
            <h1 className='section-title' data-aos='fade-up' data-aos-duration='1000'>
              Planificación
            </h1>
            <p data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000'>
              Si necesita una campaña de comunicación relevante para construir su marca,
            </p>
            <p data-aos='fade-up' data-aos-delay='400' data-aos-duration='1000'>
              <strong style={{ fontSize: '1.4rem' }}>Yok</strong> puede guiarlo paso a paso con herramientas para que obtenga la información más precisa y veraz para la toma de decisiones y así poder concretarla.
            </p>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}
