const currentMetrics = {
  totalPageViews: 59317,
  uniquePageViews: 36534,
  totalUsers: 6635,
  avgSession: '2:06',
  avgSessionMedia: '14:29',
  facebookPlaybackMinutes: '+2.1 Mill',
  userProfile: {
    male: 52.6,
    female: 47.4
  },
  userAges: {
    values: [
      {
        label: '13-17',
        percentage: 2.2
      },
      {
        label: '18-24',
        percentage: 21.9
      },
      {
        label: '25-34',
        percentage: 42.8
      },
      {
        label: '35-44',
        percentage: 25.3
      },
      {
        label: '45-54',
        percentage: 5.2
      },
      {
        label: '55-64',
        percentage: 2.0
      },
      {
        label: '65+',
        percentage: 0.6
      }
    ],
    maxValue: 45
  },
  accessChanels: {
    desktop: 17.9,
    tablet: 0.7,
    mobile: 81.4
  },
  socialMedia: {
    fbFollowers: 1214,
    igFollowers: 515
  }
}

export default currentMetrics
